var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-2"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "LinkIcon",
      "size": "18"
    }
  }), _c('h4', {
    staticClass: "mb-0 ml-75"
  }, [_vm._v(" Social Links ")])], 1)]), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "account-twitter",
      "label": "Twitter"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "account-twitter",
      "placeholder": "Add link"
    },
    model: {
      value: _vm.localOptions.socialLinks.twitter,
      callback: function callback($$v) {
        _vm.$set(_vm.localOptions.socialLinks, "twitter", $$v);
      },
      expression: "localOptions.socialLinks.twitter"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "account-facebook",
      "label": "Facebook"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "account-facebook",
      "placeholder": "Add link"
    },
    model: {
      value: _vm.localOptions.socialLinks.facebook,
      callback: function callback($$v) {
        _vm.$set(_vm.localOptions.socialLinks, "facebook", $$v);
      },
      expression: "localOptions.socialLinks.facebook"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "account-google",
      "label": "Google+"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "account-google",
      "placeholder": "Add link"
    },
    model: {
      value: _vm.localOptions.socialLinks.google,
      callback: function callback($$v) {
        _vm.$set(_vm.localOptions.socialLinks, "google", $$v);
      },
      expression: "localOptions.socialLinks.google"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "account-linkedin",
      "label": "LinkedIn"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "account-linkedin",
      "placeholder": "Add link"
    },
    model: {
      value: _vm.localOptions.socialLinks.linkedIn,
      callback: function callback($$v) {
        _vm.$set(_vm.localOptions.socialLinks, "linkedIn", $$v);
      },
      expression: "localOptions.socialLinks.linkedIn"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Instagram",
      "label-for": "account-instagram"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "account-instagram",
      "placeholder": "Add link"
    },
    model: {
      value: _vm.localOptions.socialLinks.instagram,
      callback: function callback($$v) {
        _vm.$set(_vm.localOptions.socialLinks, "instagram", $$v);
      },
      expression: "localOptions.socialLinks.instagram"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label-for": "account-quora",
      "label": "Quora"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "account-quora",
      "placeholder": "Add link"
    },
    model: {
      value: _vm.localOptions.socialLinks.quora,
      callback: function callback($$v) {
        _vm.$set(_vm.localOptions.socialLinks, "quora", $$v);
      },
      expression: "localOptions.socialLinks.quora"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('hr', {
    staticClass: "my-2"
  })]), _c('b-col', {
    staticClass: "mt-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3"
  }, [_c('feather-icon', {
    attrs: {
      "icon": "UserIcon",
      "size": "18"
    }
  }), _c('h4', {
    staticClass: "mb-0 ml-75"
  }, [_vm._v(" Profile Connections ")])], 1), _c('b-row', {
    staticClass: "text-center"
  }, [_c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "md": "3",
      "cols": "6"
    }
  }, [_c('b-card-text', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Your Twitter ")]), _c('div', {
    staticClass: "mb-1"
  }, [_c('b-avatar', {
    attrs: {
      "size": "40",
      "src": _vm.localOptions.connections.twitter.profileImg
    }
  })], 1), _c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" @" + _vm._s(_vm.localOptions.connections.twitter.id) + " ")]), _c('b-link', [_vm._v(" Disconnect ")])], 1), _c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "md": "3",
      "cols": "6"
    }
  }, [_c('b-card-text', {
    staticClass: "font-weight-bold mb-2"
  }, [_vm._v(" Your Facebook ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    }
  }, [_vm._v(" Connect ")])], 1), _c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "md": "3",
      "cols": "6"
    }
  }, [_c('b-card-text', {
    staticClass: "font-weight-bold"
  }, [_vm._v(" Your Google ")]), _c('div', {
    staticClass: "mb-1"
  }, [_c('b-avatar', {
    attrs: {
      "size": "40",
      "src": _vm.localOptions.connections.google.profileImg
    }
  })], 1), _c('b-card-text', {
    staticClass: "mb-0"
  }, [_vm._v(" @" + _vm._s(_vm.localOptions.connections.google.id) + " ")]), _c('b-link', [_vm._v(" Disconnect ")])], 1), _c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "md": "3",
      "cols": "6"
    }
  }, [_c('b-card-text', {
    staticClass: "font-weight-bold mb-2"
  }, [_vm._v(" Your GitHub ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(113, 102, 240, 0.15)',
      expression: "'rgba(113, 102, 240, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-primary"
    }
  }, [_vm._v(" Connect ")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1 mr-1",
    attrs: {
      "variant": "primary"
    }
  }, [_vm._v(" Save changes ")]), _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    staticClass: "mt-1 ml-25",
    attrs: {
      "type": "reset",
      "variant": "outline-secondary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.resetForm($event);
      }
    }
  }, [_vm._v(" Cancel ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }